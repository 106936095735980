.loginScreen {
    position: relative;
	min-height: 100vh;
	background-color: #E1E8EE;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loginWindow {
    background-color: #222;
	border-radius: 15px;
	height: 550px;
	width: 350px;
	position: relative;
	overflow: hidden;
}

.loginForm {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    width: 65%;
    z-index: 5;
    -webkit-transition: all .3s ease;
}

.formTitle {
    font-size: 20pt;
    color: white;
    text-align: center;
    margin-bottom: 30px;
}


.formField{
    border: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    box-shadow: none;
    display: block;
    height: 35px;
    line-height: 30px;
    padding: 8px 15px;
    width: 100%;
    font-size: 16px;
    border-radius: 5px;
}

.loginForm button {
    margin-top: 30px;
    background-color: #0275d8;
    opacity: 90%;
    color: white;
    border: 0;
    border-radius: 5px;
    height: 35px;
    width:100%;
}

.errorLoginMessage {
    text-align: center;
    margin-top: 30px;
    color: white
}